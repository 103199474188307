<template>
  <div class="login-page">
    <Head title="登录" link="home" />
    <Warpper top="40">
      <img class="pic" src="../../assets/images/login.png" alt />
      <div class="form-box">
        <FormItem
          type="tel"
          icon="user"
          v-model="tel"
          placeholder="请输入您的手机号"
        ></FormItem>
        <FormItem
          type="password"
          icon="password"
          v-model="password"
          placeholder="请输入您的登录密码"
        ></FormItem>
        <div class="link-box">
          <router-link to="/forget">忘记密码？</router-link>
        </div>
        <button class="login-btn" @click="onLogin()">登录</button>
        <button class="wx-btn" @click="wxLogin">微信快捷登录</button>
        <div class="link-reg">
          <router-link to="/register">立即注册</router-link>
        </div>
      </div>
    </Warpper>
  </div>
</template>

<script>
import FormItem from "components/FormItem";
import { isWX, getQueryString, processUrl } from "@/plugins/untils";
import store from "@/store";
import { reqLogin, reqGetUserInfo, reqWXLogin } from "@/network/api";
export default {
  data() {
    return {
      tel: "",
      password: "",
      openid: this.$store.getters.openid,
      isBind: this.$store.getters.wxbind,
      code: "",
    };
  },
  components: {
    FormItem,
  },
  async created() {
    this.code = getQueryString("code") || "";
    if (this.code) {
      await this.$store.dispatch("user/loginWechatAuth", this.code);
      await this.$store.dispatch("user/setLoginStatus", 2);
      this.$router.replace({
        name: "mine",
      });
    }
  },
  mounted() {},
  methods: {
    onLogin(state) {
      if (!state) {
        if (this.tel == "") {
          return this.$toast({
            message: "请输入您的手机号",
          });
        }
        if (!/^1\d{10}$/.test(this.tel)) {
          return this.$toast({
            message: "手机号有误，请重新填写",
          });
        }
        if (this.password == "") {
          return this.$toast({
            message: "请输入您的登录密码",
          });
        }
      }
      // 调用登录接口
      let option = {
        username: this.tel,
        password: this.password,
      };
      if (!this.isBind) {
        option.openid = this.openid;
      }

      reqLogin(option)
        .then(async (res) => {
          const { msg, backdata, code } = res;
          this.$toast({ message: msg });
          if (code != 0) return;
          await this.$store.dispatch("user/setToken", backdata.token);
          await this.$store.dispatch("user/setLoginStatus", 2);

          reqGetUserInfo({
            user_id: 0,
          }).then(async (res) => {
            const { msg, backdata, code } = res;
            if (code != 0) return;
            await this.$store.dispatch("user/setUserInfo", backdata.userinfo);
            this.$router.replace({
              name: "mine",
            });
          });
        })
        .catch((error) => {
          this.$toast({ message: "系统错误" });
        });
    },
    async wxLogin() {
      if (!isWX()) {
        return this.$toast({
          message: "非微信下使用账号登录",
        });
      }
      console.log(this.isBind);

      if (!this.isBind) {
        return this.$toast({
          message: "微信未绑定，请账号登录或注册",
        });
      }
      if (this.openid == "") {
        const res = await reqWXLogin({ backurl: window.location.origin });
        await this.$store.dispatch("user/setLoginStatus", 1);
        window.location.href = res.backdata.loginurl;
        return;
      }
      this.onLogin(1);
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.login-page {
  position: relative;
  width: 100%;
  background: #ffffff;
  .pic {
    width: 100%;
    height: 4.12rem;
    object-fit: cover;
    margin-bottom: 0.6rem;
  }
}
.form-box {
  width: 6.3rem;
  margin: 0 auto 0.45rem;
  .link-box {
    text-align: right;
    margin-bottom: 0.52rem;
    a {
      font-size: 0.28rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #666666;
    }
  }
  .login-btn {
    width: 100%;
    height: 0.88rem;
    background: linear-gradient(0deg, #328f96 0%, #69c4b8 100%);
    border-radius: 0.44rem;
    margin-bottom: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.34rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    border: 0;
  }
  .wx-btn {
    width: 100%;
    height: 0.88rem;
    background: linear-gradient(0deg, #01b90d 0%, #00d50b 100%);
    border-radius: 0.44rem;
    margin-bottom: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.34rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    border: 0;
    &::before {
      content: "";
      width: 0.6rem;
      height: 0.5rem;
      background: url("../../assets/images/icon_weixin2.png") no-repeat;
      background-size: 100% auto;
      margin-right: 0.32rem;
    }
  }
  button:focus {
    outline: 0;
  }
  .link-reg {
    text-align: center;
    a {
      font-size: 0.28rem;
      font-family: Microsoft YaHei;
      font-weight: 400;
      text-decoration: underline;
      color: #666666;
    }
  }
}
</style>
